import img from './assets/camping-uno.jpg';

const App = () => {
  return (
    <div className='container'>
      <h1>the personal web existence of gary scott</h1>

      <img src={img} className='img-fluid' alt='' />

      <div className='row'>
        <div className='col-sm'>
          <a href='https://github.com/garster'>github</a>
        </div>
        <div className='col-sm'>
          <a href='https://www.instagram.com/0.1.0.0.0.1.1.1/'>instagram</a>
        </div>
        <div className='col-sm'>
          <a href='https://tikicatlounge.com/'>tiki cat</a>
        </div>

        <div className='col-sm'>
          <a href='https://blog.garyscott.net/'>blog</a>
        </div>
        <div className='col-sm'>
          <a href='https://theadventuresoflizandgary.com/'>photos</a>
        </div>
      </div>
    </div>
  );
};

export default App;
